import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Settings} from '../../settings.class';
import {Observable} from 'rxjs';

@Injectable()
export class LocationService {

    constructor(private http: HttpClient) {
    }

    getLocations(lat: string | number, lon: string | number): Observable<any> {
        const url = `https://dev.virtualearth.net/REST/v1/Locations/${lat},${lon}?key=${Settings.BING_MAPS_API_KEY}&c=nl`;
        return this.http.jsonp(url, 'jsonp');
    }

}

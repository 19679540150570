export enum Routenames {
    chat = 'meldingen',
    airtable = 'commerciele-kaart-opdrachtgevers',
    projects = 'projects',
    settings = 'settings',
    login = 'login',
    towns = 'towns',
    exit = 'exit',
    consumers = 'consumers',
    contacts = 'contacts',
    register = 'register',
    confirmMail = 'confirm-email',
    users = 'users',
    changePass = 'user/change-password',
    forgotPass = 'forgot-password',
    qr = 'qr',
    restorePass = 'restore-password',
    articles = 'updates',
    timeline = 'tijdlijn',
    projectsDrilling = 'projects-drilling'
}

import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {ConfirmModalService} from './shared/confirm-modal.service';
import {ConfirmModalComponent} from './shared/confirm-modal.component';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthService} from './services/auth/auth.service';
import {ApiService} from './services/api/api.service';
import {RequestRestorePasswordComponent} from './request-restore-password/request-restore-password.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {UserDetailComponent} from './user-management/user-detail/user-detail.component';
import {RestorePasswordComponent} from './restore-password/restore-password.component';
import {UserService} from './services/user/user.service';
import {SettingsComponent} from './settings/settings.component';
import {UserListComponent} from './user-management/user-list/user-list.component';
import {UserDetailEditComponent} from './user-management/user-detail/user-detail-edit/user-detail-edit.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ModalModule} from 'ngx-bootstrap/modal';
import {MomentModule} from 'ngx-moment';
import {AuthLogComponent} from './auth-log/shared/auth-log.component';
import {AuthLogService} from './auth-log/shared/auth-log.service';
import {ProcessbarComponent} from './processbar/processbar.component';
import {WherePipe} from './where.pipe';
import {UploadFileComponent} from './upload-file/upload-file.component';
import {UploadService} from './upload-file/shared/upload.service';
import {CordovaService} from './cordova.service';
import {AngularBingMapsComponent} from './angular-bing-maps/angular-bing-maps.component';
import {WorkareasMapComponent} from './workareas-map/workareas-map.component';
import {WorkareaComponent} from './workarea/workarea.component';
import {TownService} from './services/town/town.service';
import {FirebaseService} from './firebase.service';
import {SafeBgUrlPipe} from './pipes/safe-bg-url.pipe';
import {SafeHtml} from './pipes/safe-html.pipe';
import {NewsOverviewComponent} from './news/news-overview/news-overview.component';
import {NewsDetailComponent} from './news/news-detail/news-detail.component';
import {BackButtonComponent} from './back-button/back-button.component';
import {MessageService} from './news/shared/message.service';
import {NewsEditComponent} from './news/news-edit/news-edit.component';
import {CameraService} from './camera.service';
import {ContactOverviewComponent} from './contacts/contact-overview/contact-overview.component';
import {ContactDetailComponent} from './contacts/contact-detail/contact-detail.component';
import {ContactEditComponent} from './contacts/contact-edit/contact-edit.component';
import {RegisterComponent} from './register/register.component';
import {LocationService} from './workarea/shared/location.service';
import {ConfirmEmailComponent} from './confirm-email/confirm-email.component';
import {ImageViewerComponent} from './image-viewer/image-viewer.component';
import {ChangePasswordComponent} from './user/change-password/change-password.component';
import {ExportService} from './services/export/export.service';
import {NotificationService} from './notification.service';
import {GpsService} from './gps.service';
import {Nl2brPipe} from './pipes/nl2br.pipe';
import {PullToRefreshComponent} from './components/pull-to-refresh/pull-to-refresh.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {WorkareaNawComponent} from './workarea/workarea-naw/workarea-naw.component';
import {WorkareaVogelzangComponent} from './workarea/workarea-vogelzang/workarea-vogelzang.component';
import {WorkareaBLVCComponent} from './workarea/workarea-blvc/workarea-blvc.component';
import {WorkareaHouthavenComponent} from './workarea/workarea-houthaven/workarea-houthaven.component';
import {WorkareaGeneralComponent} from './workarea/workarea-general/workarea-general.component';
import {TownsOverviewComponent} from './towns-overview/towns-overview.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {registerLocaleData} from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import {WorkareasFilterComponent} from './workareas-filter/workareas-filter.component';
import {WorkareaStraatwerkComponent} from './workarea/workarea-straatwerk/workarea-straatwerk.component';
import {ResetLoginTriesComponent} from './reset-login-tries/reset-login-tries.component';
import {DocumentsComponent} from './documents/documents.component';
import {AddDocumentComponent} from './documents/add-chapter/add-document.component';
import {PdfViewerDialogComponent} from './documents/pdf-viewer-dialog/pdf-viewer-dialog.component';
import {RenameDocumentComponent} from './documents/rename-chapter/rename-document.component';
import {CdkDropList, DragDropModule} from '@angular/cdk/drag-drop';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {ConfirmDialogComponent} from './services/confirm-dialog-service/confirm-dialog/confirm-dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import {EditDocumentDetailsComponent} from './documents/edit-document-details/edit-document-details.component';
import {ImageViewerDialogComponent} from './documents/image-viewer-dialog/image-viewer-dialog.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {CodaltInterceptor} from './codalt.interceptor';
import {TownProjectOverviewComponent} from './town-project-overview/town-project-overview.component';
import {TownProjectDetailComponent} from './town-project-detail/town-project-detail.component';
import {TownDetailComponent} from './town-detail/town-detail.component';
import {TownProjectService} from './services/townproject/town-project.service';
import {TownProjectPhaseService} from './services/townproject/town-project-phase.service';
import {TownProjectEditDialogComponent} from './town-project-edit-dialog/town-project-edit-dialog.component';
import {FieldErrorComponent} from './field-error/field-error.component';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {WorkareaDrillComponent} from './workarea/workarea-drill/workarea-drill.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {WorkareaProjectChooseDialogComponent} from './workareas-map/workarea-type-choose-dialog/workarea-project-choose-dialog.component';
import {TownListComponent} from './town-list/town-list.component';
import {LogoutComponent} from './logout/logout.component';
import {TownTableComponent} from './town-table/town-table.component';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {DocumentUploadComponent} from './document-upload/document-upload.component';
import {FileService} from './services/file.service';
import {SimpleTinyComponent} from './simple-tiny/simple-tiny.component';
import {TownReportComponent} from './town-report/town-report.component';
import {TownLogoComponent} from './town-logo/town-logo.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {TownProjectPhaseListComponent} from './town-project-detail/town-project-phase-list/town-project-phase-list.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {TownProjectPhaseEditDialogComponent} from './town-project-detail/town-project-phase-list/town-project-phase-edit-dialog/town-project-phase-edit-dialog.component';
import {TownProjectPhaseActivitiesComponent} from './town-project-detail/town-project-phase-activities/town-project-phase-activities.component';
import {
    TownProjectPhaseActivityEditDialogComponent
} from './town-project-detail/town-project-phase-activities/town-project-phase-activity-edit-dialog/town-project-phase-activity-edit-dialog.component';
import {MarkerPipe} from './pipes/marker.pipe';
import {TownProjectPhaseRepliesComponent} from './town-project-detail/town-project-phase-replies/town-project-phase-replies.component';
import {DecimalInputDirective} from './directives/decimal-input.directive';
import {ChatOverviewComponent} from './chat/chat-overview/chat-overview.component';
import {ChatMessagingComponent} from './chat/chat-messaging/chat-messaging.component';
import {ChatMessageInfoDialogComponent} from './chat/chat-message-info-dialog/chat-message-info-dialog.component';
import {ChatSettingsDialogComponent} from './chat/chat-settings-dialog/chat-settings-dialog.component';
import {IsBase64Pipe} from './pipes/is-base64.pipe';
import {WhereLessPipe} from './pipes/whereLess.pipe';
import {NotByIdPipe} from './pipes/not-by-id.pipe';
import {ByIdPipe} from './pipes/by-id.pipe';
import {WhereGreaterOrEqualPipe} from './pipes/whereGreaterOrEqual.pipe';
import {CodaltImageComponent} from './codalt-image/codalt-image.component';
import {SafeSrcPipe} from './pipes/safe-src.pipe';
import {ChatService} from './chat/chat.service';
import {ChatMessageService} from './chat/chat-message.service';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {UiScrollModule} from 'ngx-ui-scroll';
import {NewChatDialogComponent} from './chat/new-chat-dialog/new-chat-dialog.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ChatComponent} from './chat/chat/chat.component';
import {FilterPipe} from './pipes/filter.pipe';
import {MatRadioModule} from '@angular/material/radio';
import {ProjectsComponent} from './projects/projects.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ProjecttypePipe} from './pipes/projecttype.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AddTownprojectModalComponent} from './settings/add-townproject-modal/add-townproject-modal.component';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {ArticleEditComponent} from './articles/article-edit/article-edit.component';
import {ArticlesComponent} from './articles/articles.component';
import {ArticleDetailComponent} from './articles/article-detail/article-detail.component';
import {CodaltComponent} from './codalt.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {FileManagerComponent} from './file-manager/file-manager.component';
import {FileSelectionDialogComponent} from './file-manager/file-selection-dialog/file-selection-dialog.component';
import {FileAddItemComponent} from './file-manager/file-add-item-dialog/file-add-item.component';
import {ChooseFileSourceComponent} from './file-manager/choose-file-source/choose-file-source.component';
import {ArticleService} from './services/article.service';
import {CanDeactivateForm} from './can-deactivate-form';
import '@angular/common/locales/global/nl';
import {TimelineTownComponent} from './timeline/timeline-town/timeline-town.component';
import {TimelineEditComponent} from './timeline/timeline-edit/timeline-edit.component';
import {TimelineDetailComponent} from './timeline/timeline-detail/timeline-detail.component';
import {TimelineService} from './services/timeline.service';
import {QrComponent} from './qr/qr.component';
import {QRCodeModule} from 'angularx-qrcode';
import {ChatTypePipe} from './pipes/chat-type-icon.pipe';
import {DndModule} from 'ngx-drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {PinchZoomModule} from 'ngx-pinch-zoom';
import {TownProjectDescriptionDialogComponent} from './town-project-detail/town-project-description-dialog/town-project-description-dialog.component';
import {WorkareaHistoryDialogComponent} from './workarea/workarea-history-dialog/workarea-history-dialog.component';
import {AirtableMapComponent} from './airtable-map/airtable-map.component';
import {WorkareaService} from './services/workarea/workarea.service';
import {AirtableService} from './services/airtable/airtable.service';
import {StatusDisabledPipe} from './pipes/status-disabled.pipe';
import {TimelineComponent} from './timeline/timeline/timeline.component';
import {SurveyAnswerComponent} from './survey/survey-answer/survey-answer.component';
import {SurveyAnswersComponent} from './survey/survey-answers/survey-answers.component';
import {SurveyEditComponent} from './survey/survey-edit/survey-edit.component';
import {SurveyService} from './services/survey.service';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ShortNumberPipe} from './pipes/short-number.pipe';
import {SelectedOptionsPipe} from './pipes/selected-options.pipe.ts';
import {CustomErrorHandlerService} from './custom-error-handler-service';
import {LoggerService} from './services/logger.service';
import {TownProjectConsumersComponent} from './town-project-consumers/town-project-consumers.component';

registerLocaleData(localeNL, 'nl');


declare var Hammer: any;

export class MyHammerConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        let mc = new Hammer(element, {
            touchAction: "pan-y"
        });
        return mc;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        RequestRestorePasswordComponent,
        UserManagementComponent,
        UserDetailComponent,
        AuthLogComponent,
        UserDetailComponent,
        RestorePasswordComponent,
        SettingsComponent,
        UserListComponent,
        UserDetailEditComponent,
        UserProfileComponent,
        ConfirmModalComponent,
        UserProfileComponent,
        ProcessbarComponent,
        WherePipe,
        Nl2brPipe,
        UploadFileComponent,
        AngularBingMapsComponent,
        WorkareasMapComponent,
        WorkareaComponent,
        NewsOverviewComponent,
        NewsDetailComponent,
        SafeBgUrlPipe,
        ProjecttypePipe,
        ChatTypePipe,
        SafeHtml,
        BackButtonComponent,
        NewsEditComponent,
        ContactOverviewComponent,
        ContactDetailComponent,
        ContactEditComponent,
        RegisterComponent,
        ConfirmEmailComponent,
        ImageViewerComponent,
        ChangePasswordComponent,
        PullToRefreshComponent,
        WorkareaNawComponent,
        WorkareaVogelzangComponent,
        WorkareaBLVCComponent,
        WorkareaHouthavenComponent,
        WorkareaGeneralComponent,
        TownsOverviewComponent,
        WorkareasFilterComponent,
        WorkareaStraatwerkComponent,
        ResetLoginTriesComponent,
        DocumentsComponent,
        AddDocumentComponent,
        AirtableMapComponent,
        PdfViewerDialogComponent,
        RenameDocumentComponent,
        ConfirmDialogComponent,
        EditDocumentDetailsComponent,
        ImageViewerDialogComponent,
        TownProjectOverviewComponent,
        TownProjectDetailComponent,
        TownDetailComponent,
        WorkareaDrillComponent,
        TownDetailComponent,
        TownProjectEditDialogComponent,
        FieldErrorComponent,
        TownListComponent,
        TownTableComponent,
        LogoutComponent,
        WorkareaProjectChooseDialogComponent,
        DocumentUploadComponent,
        SimpleTinyComponent,
        TownReportComponent,
        TownLogoComponent,
        TownProjectPhaseListComponent,
        TownProjectPhaseEditDialogComponent,
        TownProjectPhaseActivitiesComponent,
        TownProjectPhaseActivityEditDialogComponent,
        MarkerPipe,
        TownProjectPhaseRepliesComponent,
        DecimalInputDirective,
        ChatOverviewComponent,
        ChatMessagingComponent,
        ChatMessageInfoDialogComponent,
        ChatSettingsDialogComponent,
        IsBase64Pipe,
        WhereLessPipe,
        NotByIdPipe,
        ByIdPipe,
        WhereGreaterOrEqualPipe,
        CodaltImageComponent,
        SafeSrcPipe,
        NewChatDialogComponent,
        ChatComponent,
        FilterPipe,
        ProjectsComponent,
        ProjecttypePipe,
        AddTownprojectModalComponent,
        ArticleEditComponent,
        ArticlesComponent,
        ArticleDetailComponent,
        CodaltComponent,
        FileManagerComponent,
        FileSelectionDialogComponent,
        ChooseFileSourceComponent,
        FileAddItemComponent,
        TimelineComponent,
        TimelineTownComponent,
        TimelineEditComponent,
        TimelineDetailComponent,
        QrComponent,
        TownProjectDescriptionDialogComponent,
        WorkareaHistoryDialogComponent,
        StatusDisabledPipe,
        SurveyAnswerComponent,
        SurveyAnswersComponent,
        SurveyEditComponent,
        ShortNumberPipe,
        SelectedOptionsPipe,
        TownProjectConsumersComponent
    ],
    entryComponents: [],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        HammerModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        MomentModule,
        NgSelectModule,
        TypeaheadModule.forRoot(),
        PaginationModule.forRoot(),
        BsDropdownModule.forRoot(),
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatInputModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatDialogModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        DragDropModule,
        MatIconModule,
        MatMenuModule,
        SwiperModule,
        MatAutocompleteModule,
        MatListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTableModule,
        MatRadioModule,
        MatProgressBarModule,
        MatRippleModule,
        AngularSvgIconModule.forRoot(),
        UiScrollModule,
        MatSortModule,
        MatTooltipModule,
        VirtualScrollerModule,
        QRCodeModule,
        DndModule,
        ScrollingModule,
        PinchZoomModule,
        NgxChartsModule
    ],
    providers: [
        ApiService,
        FileService,
        AuthService,
        UserService,
        ConfirmModalService,
        AuthLogService,
        UploadService,
        CordovaService,
        WorkareaService,
        AirtableService,
        TownService,
        FirebaseService,
        MessageService,
        CameraService,
        LocationService,
        ExportService,
        NotificationService,
        TownProjectService,
        TownProjectPhaseService,
        GpsService,
        CdkDropList,
        ChatService,
        ChatMessageService,
        ArticleService,
        TimelineService,
        CanDeactivateForm,
        IsBase64Pipe,
        ByIdPipe,
        StatusDisabledPipe,
        Keyboard,
        FirebaseX,
        MatBottomSheet,
        SurveyService,
        ShortNumberPipe,
        LoggerService,
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
        {provide: HTTP_INTERCEPTORS, useClass: CodaltInterceptor, multi: true},
        {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
        {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
        {provide: LOCALE_ID, useValue: 'nl'}
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
